/**
 * Adds a space at a set point within a string
 *
 * @example
 * insertSpaceAt({str: 'example', index: 3 })
 * // returns 'exa mple'
 *
 * @param  {Object} obj - Destructuring parameter
 * @param  {string} obj.str
 * @param  {number} obj.index
 * @return {string} Returns a string with space inserted
 */

const insertSpaceAt = ({ str, index }) => {
  const arr = str.split('')
  return [...arr.slice(0, index), ' ', ...arr.slice(index)].join('')
}

export default insertSpaceAt

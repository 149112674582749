import React from 'react'
import { bool, func, object } from 'prop-types'

import GlobalError from '@/component/Landmark/GlobalError'

import ButtonStandard from '@/component/Primitive/ButtonStandard'
import ButtonStandardArrow from '@/component/Primitive/ButtonStandardArrow'
import Container from '@/component/Primitive/Container'
import DecoratedTextControl from '@/component/Primitive/DecoratedTextControl'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import Icon from '@/component/Primitive/Icon'
import InfoSummary from '@/component/Primitive/InfoSummary'
import Notification from '@/component/Primitive/Notification'
import Panel from '@/component/Primitive/Panel'
import Prose from '@/component/Primitive/Prose'
import Section from '@/component/Primitive/Section'
import ShrinkWrap from '@/component/Primitive/ShrinkWrap'
import SmartLink from '@/component/Primitive/SmartLink'
import Stack from '@/component/Primitive/Stack'
import TextAlign from '@/component/Primitive/TextAlign'
import TextControlAddon from '@/component/Primitive/TextControlAddon'
import Type from '@/component/Primitive/Type'
import Vehicle from '@/component/Primitive/Vehicle'
import VisuallyHidden from '@/component/Primitive/VisuallyHidden'
import NumberPlate from '@/component/Primitive/NumberPlate'

const VehiclePage = ({
  lookupLoading,
  onLookup,
  resetVehicle,
  onSubmit,
  onChange,
  submitEnabled,
  vehicle,
  submitting,
  vehicleNotFound,
  error
}) => (
  <>
    <form method="post" onSubmit={onSubmit}>
      <VisuallyHidden>
        <h2>Vehicle</h2>
      </VisuallyHidden>

      {error && <GlobalError />}

      <Section padded minHeight>
        <Container gutter center>
          <Stack>
            {!vehicle.name && (
              <FieldTemplate
                label="Enter your Reg Number"
                controlName="regNumber"
              >
                <ShrinkWrap fullWidth>
                  <ShrinkWrap.Item vAlign="middle">
                    <DecoratedTextControl
                      name="regNumber"
                      type="text"
                      value={vehicle.regNumber}
                      required
                      maxLength={10}
                      onChange={onChange}
                      before={
                        <TextControlAddon prefix>
                          <Icon type="numberplate-decoration" a11yText="" />
                        </TextControlAddon>
                      }
                      form="lookup"
                    />
                  </ShrinkWrap.Item>

                  <ShrinkWrap.Item shrink vAlign="middle">
                    <ButtonStandard
                      size="l"
                      alt
                      loading={lookupLoading}
                      onClick={onLookup}
                      type="submit"
                      form="lookup"
                      disabled={
                        !vehicle.regNumber || vehicle.regNumber.length === 0
                      }
                    >
                      Lookup
                    </ButtonStandard>
                  </ShrinkWrap.Item>
                </ShrinkWrap>
              </FieldTemplate>
            )}

            {vehicle.name && (
              <Panel border padded highlighted>
                <Container gutter>
                  <InfoSummary
                    src={vehicle.formattedImage}
                    imageSize="contain"
                    imageAddon={
                      <Type size="xs" tight color="coolgrey-l50">
                        {vehicle.image
                          ? 'Image for illustrative purposes only'
                          : 'Image unavailable for this vehicle'}
                      </Type>
                    }
                  >
                    <Vehicle
                      name={vehicle.name}
                      color={vehicle.color}
                      vehicleTransmission={vehicle.vehicleTransmission}
                      vehicleEngine={vehicle.vehicleEngine}
                      productionDate={vehicle.productionDate}
                    />
                    <Prose>
                      <TextAlign center>
                        <NumberPlate children={vehicle.regNumber} />
                      </TextAlign>
                    </Prose>
                  </InfoSummary>
                </Container>
              </Panel>
            )}

            {vehicleNotFound && !submitEnabled && (
              <Notification status="notice">
                We couldn’t find your vehicle. Please{' '}
                <a href="tel:03300120242">call us on 0330&nbsp;012&nbsp;0242</a>{' '}
                for assistance.
              </Notification>
            )}

            {vehicle.name && (
              <Prose>
                <TextAlign center>
                  <SmartLink onClick={resetVehicle}>
                    Not your vehicle?
                  </SmartLink>
                </TextAlign>
              </Prose>
            )}
          </Stack>
        </Container>
      </Section>

      <Section backgroundLight shadowTop>
        <Container gutter center>
          <TextAlign center>
            <ButtonStandardArrow
              type="submit"
              disabled={!submitEnabled}
              loading={submitting}
            >
              Continue
            </ButtonStandardArrow>
          </TextAlign>
        </Container>
      </Section>
    </form>
    {/* This form only exists to capture the submit event for the reg lookup button */}
    <form
      id="lookup"
      method="post"
      action=""
      onSubmit={(e) => e.preventDefault()}
    />
  </>
)

VehiclePage.propTypes = {
  lookupLoading: bool,
  onLookup: func,
  resetVehicle: func,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitEnabled: bool,
  vehicle: object, // TODO: shape
  submitting: bool,
  vehicleNotFound: bool,
  error: bool
}

export default VehiclePage

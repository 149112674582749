import React from 'react'
import { bool, node } from 'prop-types'
import classNames from 'classnames'

import styles from './TextControlAddon.module.scss'

/**
 * A visual addition to a text-type input, used with the
 * `<DecoratedTextControl />` component.
 */

const TextControlAddon = ({ children, prefix, suffix }) => (
  <div
    className={classNames(
      styles.TextControlAddon,
      prefix && styles.prefix,
      suffix && styles.suffix
    )}
  >
    {children}
  </div>
)

TextControlAddon.propTypes = {
  children: node.isRequired,
  prefix: bool,
  suffix: bool
}

export default TextControlAddon

import React from 'react'
import { node, oneOf, string } from 'prop-types'
import styles from './InfoSummary.module.scss'

import BackgroundImage from '../BackgroundImage'

/**
 * Displays an image next to some text-based content, with optional
 * decorative overlay elements for both the image and the content areas.
 */

const InfoSummary = ({
  children,
  src,
  imageAddon,
  contentAddon,
  imageSize
}) => (
  <div className={styles.InfoSummary}>
    <div className={styles.InfoSummaryImage}>
      <div className={styles.InfoSummaryImageSmall}>
        <BackgroundImage ratio={100 / 285} alt="" src={src} size={imageSize} />
      </div>
      <div className={styles.InfoSummaryImageLarge}>
        <BackgroundImage ratio={126 / 216} alt="" src={src} fillContainer />
      </div>
      {imageAddon && (
        <div className={styles.InfoSummaryImageAddon}>{imageAddon}</div>
      )}
    </div>
    <div className={styles.InfoSummaryContent}>
      {contentAddon && (
        <div className={styles.InfoSummaryContentAddon}>{contentAddon}</div>
      )}
      <div className={styles.InfoSummaryContentInner}>{children}</div>
    </div>
  </div>
)

InfoSummary.propTypes = {
  imageSize: 'cover'
}

InfoSummary.propTypes = {
  children: node.isRequired,
  src: string,
  imageAddon: node,
  contentAddon: node,
  imageSize: oneOf(['contain', 'cover'])
}

export default InfoSummary

import React from 'react'
import { node } from 'prop-types'
import classNames from 'classnames'

import styles from './DecoratedTextControl.module.scss'

import TextControl from '../TextControl'

/**
 * Adds icons or buttons before and/or after input content.
 *
 * By default, pointer events are disabled on the additional content, to
 * allow clicks to pass through to the input below. If the additional
 * content is interactive, additional props need to be passed.
 */

const DecoratedTextControl = ({ before, after, ...other }) => (
  <div
    className={classNames(
      styles.DecoratedTextControl,
      before && styles.before,
      after && styles.after
    )}
  >
    {before && (
      <div className={styles.DecoratedTextControlDecoration}>{before}</div>
    )}
    <TextControl {...other} />
    {after && (
      <div className={styles.DecoratedTextControlDecoration}>{after}</div>
    )}
  </div>
)

DecoratedTextControl.propTypes = {
  before: node,
  after: node
}

export default DecoratedTextControl

import React from 'react'
import { bool, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './NumberPlate.module.scss'
import formatRegistration from '@/lib/format-registration'

import Icon from '@/component/Primitive/Icon'

const NumberPlate = ({ children, decoration, outline, size, squared }) => (
  <div
    className={classNames(
      styles.NumberPlate,
      size && styles[`size-${size}`],
      squared && styles.squared,
      outline && styles.outline
    )}
  >
    {decoration && (
      <div className={styles.NumberPlateDecoration}>
        <Icon type="numberplate-decoration" a11yText="" width={14} />
      </div>
    )}
    <div className={styles.NumberPlateContent}>
      {formatRegistration(children) || <>&nbsp;</>}
    </div>
  </div>
)

NumberPlate.defaultProps = {
  size: 'm'
}

NumberPlate.propTypes = {
  children: string.isRequired,
  decoration: bool,
  outline: bool,
  size: oneOf(['s', 'm', 'l']),
  squared: bool
}

export default NumberPlate

import createDarkroomUrlBuilder from 'darkroom-url-builder'

const buildImageUrl = (props) => {
  const { resource, width, height, mode } = props
  const urlBuilder = createDarkroomUrlBuilder(
    process.env.DR_URL,
    process.env.DR_SALT
  )

  if (!resource) return ''

  let image = urlBuilder().resource(resource)

  if (width) image = image.width(parseInt(width))
  if (height) image = image.height(parseInt(height))
  if (mode) image = image.mode(mode)

  return image.url()
}

export default buildImageUrl

import insertSpaceAt from '@/lib/insert-space-at'

/**
 * Formats a registration-plate-style string to include correct spacing and casing.
 *
 * If the input parameter already includes spacing, it is returned as-is.
 *
 * Supported formats:
 * - 'Current' style plates are the registrations currently used for new registrations and are made up of the form; 2 letters, 2 numbers and then 3 letters. Example: `AB12 CDE`
 * - 'Dateless' style fixed price plates are registrations that can be assigned to any age vehicle and consist of; 3 letters and 3 numbers. Example: `ABC 123`
 * - 'Prefix' style plates are the registrations that were used prior to 2001 and consist of; 1 letter, 1-3 numbers and then 3 letters. Example: `A123 BCD`
 * - 'Suffix' style plates are the registrations that were used prior to 1983 and consist of; 3 letters, 1-3 numbers and then 1 letter. Example: `ABC 123D`
 *
 * @example
 * formatRegistration('AB12CDE')
 * // returns 'ABCD CDE'
 *
 * @example
 * formatRegistration('A123ABC')
 * // returns 'A123 ABC'
 *
 * @param  {string} regRaw - Registration plate
 * @return {string} The same registration plate value, with added spacing
 */

const formatRegistration = (regRaw) => {
  if (!regRaw) return

  // If the input data already contains formatting, return it as-is
  if (regRaw.includes(' ')) return regRaw

  const regSafe = regRaw.toUpperCase().replace(/\W/g, '')
  if (regSafe.length < 5) return regSafe

  // Current: AB12 CDE
  if (regSafe.length === 7 && regSafe.match(/^[A-Z]{2}\d{2}[A-Z]{3}$/)) {
    return insertSpaceAt({ str: regSafe, index: regSafe.length - 3 })
  }

  // Dateless: ABC 123
  if (regSafe.length === 6 && regSafe.match(/[A-Z]{3}\d{3}/)) {
    return insertSpaceAt({ str: regSafe, index: regSafe.length - 3 })
  }

  // Prefix: A123 BCD
  if (
    regSafe.length >= 5 &&
    regSafe.length <= 7 &&
    regSafe.match(/^[A-Z]\d{1,3}[A-Z]{3}$/)
  ) {
    return insertSpaceAt({ str: regSafe, index: regSafe.length - 3 })
  }

  // Suffix: ABC 123D
  if (
    regSafe.length >= 5 &&
    regSafe.length <= 7 &&
    regSafe.match(/^[A-Z]{3}\d{1,3}[A-Z]$/)
  ) {
    return insertSpaceAt({ str: regSafe, index: 3 })
  }

  return regSafe
}

export default formatRegistration

import React from 'react'

import Section from '../../Primitive/Section'
import Container from '../../Primitive/Container'
import Hide from '../../Primitive/Hide'
import Notification from '../../Primitive/Notification'

const GlobalError = () => (
  <Section padded>
    <Container gutter center>
      <Notification status="error" icon="error">
        <div>Something’s gone wrong.</div>
        <div>
          Please try again later, or{' '}
          <a href="tel:03300082220">
            call us
            <Hide below="tablet" as="span">
              {' '}
              on 0330&nbsp;008&nbsp;2220
            </Hide>
            .
          </a>
        </div>
      </Notification>
    </Container>
  </Section>
)

export default GlobalError

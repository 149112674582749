import React, { useState, useContext, useEffect } from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import TagManager from 'react-gtm-module'

import navItems from '../src/lib/nav-items'
import { footerConfig } from '../src/lib/footer-config'

import { lookupVehicle } from '../src/lib/rtc-api'
import { FormContext } from '../src/component/Context/FormContext'
import { DataContext } from '../src/component/Context/DataContext'
import buildImageUrl from 'src/lib/media-url-maker'
import SiteWrapper from '@/component/Landmark/SiteWrapper'
import VehiclePage from 'src/component/Page/Vehicle'

export default function Vehicle({ registration, ssrVehicle }) {
  const router = useRouter()

  const [lookupLoading, toggleLookupLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [showModelDropdown, setShowModelDropdown] = useState(false)
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [vehicleNotFound, setvehicleNotFound] = useState(false)

  const {
    form: { dealership, vehicle },
    dispatch
  } = useContext(FormContext)

  const {
    data: { models }
  } = useContext(DataContext)

  useEffect(() => {
    dispatch({
      type: 'SET_VEHICLE',
      vehicle: { regNumber: registration }
    })

    if (ssrVehicle) {
      processVehicleData(ssrVehicle)
    } else if (registration) {
      handleLookup()
    }
  }, [])

  useEffect(() => {
    if (vehicle.regNumber && vehicle.name && vehicle.color) {
      setSubmitEnabled(true)
    } else {
      setSubmitEnabled(false)
    }
  }, [dealership, vehicle.color, vehicle.name, vehicle.regNumber, router])

  const handleLookup = async () => {
    toggleLookupLoading(true)

    try {
      const res = await lookupVehicle(vehicle.regNumber)
      toggleLookupLoading(false)
      setvehicleNotFound(false)
      processVehicleData(res)
    } catch (e) {
      setvehicleNotFound(true)
      toggleLookupLoading(false)
    }
  }

  const processVehicleData = (res) => {
    const model = models.filter((model) =>
      res.Model.toLowerCase().includes(model.model.toLowerCase())
    )

    const vehicleModel = {
      make: res.Make,
      modelText: res.Model,
      name: res.Description,
      color: res.Colour,
      vehicleTransmission: res.Transmission,
      vehicleEngine: `${res.Fuel}, Engine Size: ${res.EngineCapacity}`,
      productionDate: `${res.YearOfManufacture}`,
      model: '',
      formattedImage: buildImageUrl({
        resource: 'daabff48465e8d00cc6619f290082f39',
        width: 400,
        height: 225,
        mode: 'pad'
      }),
      fullDetails: res
    }

    if (model.length === 1) {
      setShowModelDropdown(false)
      vehicleModel.model = model[0].value
    } else {
      setShowModelDropdown(true)
    }

    dispatch({
      type: 'SET_VEHICLE',
      vehicle: vehicleModel
    })

    dispatch({
      type: 'SET_DEALERSHIP',
      dealership: null
    })
  }

  const handleChange = (e) => {
    dispatch({
      type: 'SET_VEHICLE',
      vehicle: {
        [e.target.name]: e.target.value
      }
    })
  }

  const resetVehicle = () => {
    dispatch({
      type: 'SET_VEHICLE',
      vehicle: null
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    sessionStorage.setItem('registration', vehicle.regNumber)

    TagManager.dataLayer({
      dataLayer: { event: 'button-clicked', type: 'goto location' }
    })

    router.push('/location')
  }

  return (
    <>
      <Head>
        <title>Vehicle Details - Citygate Service Booking</title>
      </Head>
      <SiteWrapper
        background="dashboard"
        title="Book your service"
        navItems={navItems}
        pathname={router.pathname}
        footerProps={footerConfig}
      >
        <VehiclePage
          onLookup={handleLookup}
          vehicle={vehicle}
          lookupLoading={lookupLoading}
          resetVehicle={resetVehicle}
          submitEnabled={submitEnabled}
          onSubmit={handleSubmit}
          onChange={handleChange}
          showModelDropdown={showModelDropdown}
          submitting={submitting}
          vehicleNotFound={vehicleNotFound}
        />
      </SiteWrapper>
    </>
  )
}

Vehicle.propTypes = {
  registration: PropTypes.string,
  ssrVehicle: PropTypes.object
}

export const getServerSideProps = async ({ req, res, query }) => {
  let body

  if (req.method === 'POST') {
    body = await new Promise((resolve) => {
      let text = ''
      req.on('data', (chunk) => {
        text += chunk
      })
      req.on('end', () => {
        const parsedBody = {}
        for (const pair of text.split('&')) {
          const [key, value] = pair.split('=')
          parsedBody[decodeURIComponent(key)] = decodeURIComponent(value)
        }
        resolve(parsedBody)
      })
    })
  }

  const bodyRegistration = body && body.registration
  const queryRegistration = query && query.registration

  const registration = bodyRegistration || queryRegistration || null

  let ssrVehicle = null
  if (registration) {
    try {
      ssrVehicle = await lookupVehicle(registration)
    } catch (error) {
      console.error('Error looking up vehicle:', error)
    }
  }

  return {
    props: {
      registration,
      ssrVehicle
    }
  }
}
